<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="preferences">
        <v-validate-observe ref=observer v-slot="{ invalid, touched }">
          <h1>
            <i :class="['bx', 'bx-cog']"></i>
            {{ $t('merchants.preferences.title') }}
          </h1>

          <!-- <action-bar v-bind="{item,resource}" @action="action" /> -->

          <div class="row row-cols-1 row-cols-md-2 mt-4">
            <div class="col mb-4">

              <b-card>
                <template #header>
                  <i class="bx bxs-component"></i>
                  {{ $t("merchants.preferences.content-types") }}
                </template>

                <p v-text="$t('merchants.preferences.content-types-info')"></p>

                <form-input 
                  label="merchants.preferences.content-types" 
                  icon="bxs-component" 
                  v-model="preferences.content_types"
                  :description="$t('merchants.preferences.content-types-desc')"
                >
                  <v-select 
                    taggable 
                    multiple 
                    no-drop
                    v-model="preferences.content_types" 
                  />
                </form-input>

                <form-input 
                  label="merchants.preferences.default-content-type" 
                  icon="bxs-component"
                  v-model="preferences.default_content_type"
                  type="select"
                  :options="preferences.content_types"
                  :reduce="o => o"
                  :clearable="true" 

                />
              </b-card>

            </div>
            <div class="col mb-4">

            </div>
          </div>

          <b-card class="bottom-action-bar mb-2">
            <b-button :disabled="invalid" @click="save" variant="primary" class="float-right">
              {{ $t("item.save") }}
            </b-button>
            <span v-if="touched && invalid" class="pt-2 px-2 text-danger float-right">{{$t('validation.form-invalid')}}</span>
            <span v-show="justSaved" class="pt-2 px-2 text-success float-right">{{$t('merchants.preferences.saved')}}</span>
            <!-- <b-button @click="$emit('cancel')" variant="light">
              {{ $t("item.cancel") }}
            </b-button> -->
          </b-card>

        </v-validate-observe>
      </div>
      <debug v-bind="{data:{preferences}}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import FormInput from "@/components/Form/Input";

import Debug from '@/components/Debug'
import { 
  ValidationObserver as vValidateObserve, 
} from 'vee-validate';
import api from '@/api';
import { mapMutations } from 'vuex';

export default {
  components: {
    MainLayout,
    Debug,
    vValidateObserve,
    FormInput
  },
  data() {
    return {
      loading: true,
      justSaved: false,
      preferences: null,
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    ...mapMutations('auth',['setPreferences']),
    async fetch() {
      const {data: {data}} = await api.get('merchants/preferences')
      this.preferences = data
      this.loading = false
    },
    async save() {
      this.loading = true
      try {
        const {data: {data}} = await api.put('merchants/preferences',this.preferences)
        this.preferences = data
        this.setPreferences(data)
        this.justSaved = true
        setTimeout(() => {this.justSaved = false},3000)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>